import React from "react"
import Img from "gatsby-image"

const ImageOverlay = (props) => (
        <div className={props.overlayClass} onClick={props.backdropFunction}>
            <div className="image-contain">
                <Img fluid={props.featuredImage.childImageSharp.fluid} />
            </div>
            <div className="close-overlay">
                Schließen
            </div>
        </div>
        )

export default ImageOverlay