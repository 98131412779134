import React, { Component } from "react" 
import { Helmet } from "react-helmet"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Header from "../header"
import Sidebar from "../sidebar"
import ReferenceCard from "./reference-card"

class ReferenzenLayout extends Component {

    state = {
        sidebarHide: true,
        sidebarClass: 'sidebar',
    }

    handleToggle = () => {
        console.log(this.state.sidebarHide)
        this.setState((prevState) => {
            if(prevState.sidebarHide){
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar sidebar-show-mobile',
                }
            } else {
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar',
                }
            }
            
        })
    }

    render() {
        return (
            <div className="main" style={{ height: '100%' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Ines Puettcher Pflege</title>
                </Helmet>
                <Header clickFunction={this.handleToggle} menuState={this.state.sidebarHide} /> 
                <div className="wrapper">
                    <Sidebar sidebarClass={this.state.sidebarClass} />
                    <Container fluid className="mainCol">
                        <Row>
                            <Col>
                            <div className="content">
                            <h1>Referenzen von Patienten</h1>
                                {this.props.pageContent.map((entry)=> (
                                    <ReferenceCard className="PostCard"
                                        cardTitle={entry.node.frontmatter.title}
                                        postDate={entry.node.frontmatter.date.split("/").join(".")}
                                        linkToPost={entry.node.fields.slug}
                                        postExcerpt={entry.node.html}
                                        postImage={entry.node.frontmatter.featuredImage}
                                    />
                                ))}
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default ReferenzenLayout