import React, { Component } from "react"

import Img from "gatsby-image"


import Card from "react-bootstrap/Card"
import ImageOverlay from "./image-overlay"

class ReferenceCard extends Component{

    state= {
        overlayHide: true,
        overlayClass: 'image-overlay',
    }

    handleOverlayToggle = () => {
        console.log(this.state.overlayHide)
        this.setState((prevState) => {
            if(prevState.overlayHide){
                return {overlayHide: !prevState.overlayHide,
                    overlayClass: 'overlay-show',
                }
            } else {
                return {overlayHide: !prevState.overlayHide,
                    overlayClass: 'image-overlay',
                }
            }
            
        })
    }

    render(){
        return(
            <div>
                <Card>
                    <Card.Body>
                        <i className="ref-date">
                            Am {(() => {
                                const dateString = this.props.postDate.split("/")
                                const Month = dateString[0]
                                dateString.splice(2,0, Month)
                                dateString.splice(0,1)
                                dateString.join(".")
                                return dateString
                                })()} schrieb {this.props.cardTitle}
                        </i>
                        {this.props.postImage !== null &&
                            <div className="post-image" onClick={this.handleOverlayToggle}>  
                                <Img fluid={this.props.postImage.childImageSharp.fluid} />
                            </div>
                        }
                        <div dangerouslySetInnerHTML={{__html: this.props.postExcerpt}}></div>
                    </Card.Body>
                </Card>
                {this.props.postImage !== null &&
                <ImageOverlay 
                    overlayClass={this.state.overlayClass}
                    overlayImage={this.props.featureImage}
                    backdropFunction={this.handleOverlayToggle}
                    featuredImage={this.props.postImage}
                />
                }
            </div>
        )   
    }

}
export default ReferenceCard